import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { TableChart as TableIcon } from "@material-ui/icons";
import stringData from "../stringFile";

const StyledTableCell = styled(TableCell)({
  paddingLeft: "10px",
  paddingRight: "10px",
});

const ColumnSettingsModal = ({
  columnSettings,
  setColumnSettings,
  onUpdateColumns,
  tableName = `usersControlTableColumnSettings`,
}: any) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = useState([
    { field: "UserId", isVisible: true },
    { field: "Avatar", isVisible: true },
    { field: "FirstName", isVisible: true },
    { field: "LastName", isVisible: true },
    { field: "MobileCountryCode", isVisible: true },
    { field: "MobileNumber", isVisible: true },
    { field: "Email", isVisible: true },
    { field: "EmailVerifiedStatus", isVisible: true },
    { field: "DeviceType", isVisible: true },
    { field: "CreatedDate", isVisible: true },
    { field: "HasActiveSOS", isVisible: true },
    { field: "FriendsCount", isVisible: true },
    { field: "GuardiansCount", isVisible: true },
    { field: "DependantsCount", isVisible: true },
    { field: "HasPendingInvites", isVisible: true },
    { field: "LastOnline", isVisible: true },
    { field: "IsLoggedOut", isVisible: true },
  ]);

  const handleToggle = (field: any) => {
    const newSettings = columnSettings.map((col: any) =>
      col.field === field ? { ...col, isVisible: !col.isVisible } : col
    );
    setColumnSettings(newSettings);
    localStorage.setItem("gridColumnSettings", JSON.stringify(newSettings));
    onUpdateColumns(newSettings);
  };

  const handleReset = () => {
    const newSettings = columnSettings.map((col: any) => ({
      ...col,
      isVisible: true,
    }));
    setColumnSettings(newSettings);
    localStorage.setItem("gridColumnSettings", JSON.stringify(newSettings     ));
    onUpdateColumns(newSettings);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="warning"
        aria-haspopup="true"
        fullWidth={true}
        onClick={handleClickOpen}
      >
        <TableIcon htmlColor="#fff" />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Column Settings"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Table>
              <TableBody>
                {columnSettings.map((col: any) => (
                  <TableRow>
                    <StyledTableCell align="left">{col.field}</StyledTableCell>
                    <StyledTableCell>
                      <FormControl fullWidth={true}>
                        <Switch
                          checked={col.isVisible}
                          onChange={() => handleToggle(col.field)}
                        />
                      </FormControl>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} variant="contained" color="warning">
            Reset Columns
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ColumnSettingsModal;
