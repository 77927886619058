import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { grey, orange } from "@material-ui/core/colors";
import { Helmet } from "react-helmet-async";
import { spacing, width } from "@material-ui/system";
import { darken } from "polished";
import { tableCellClasses } from "@material-ui/core/TableCell";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";
import UserNetworkTabs from "../users/UserNetworkTabs";
import {
  ArrowDropUp as Ascending,
  ArrowDropDown as Descending,
  CheckCircleOutlineRounded,
  Visibility,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import FilterDialog from "../users/FilterDialog";
import {
  CircularProgress,
  Avatar,
  IconButton,
  CardContent,
  Chip,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  Container,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card as DefaultCard,
  Box,
  Drawer,
} from "@material-ui/core";
import UsersList from "../dashboardusers/UsersList";
import Loader from "../../components/Loader";
import { GlobalContext } from "../../contexts/GlobalContext";

const StyledTableCell = styled(TableCell)({
  padding: "5px",
});
const FixedWidthTableCell = styled(StyledTableCell)({
  width: "50%",
});
export type User = {};

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const BigAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const ACard = styled(MuiCard)(spacing);
const Card = styled(ACard)`
  cursor: pointer;
`;
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

const SearchField = (props: any) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input
        placeholder={stringData.inputPlaceholder.leelouUserSearch}
        onChange={props.oc}
        value={props.value}
      />
    </Search>
  );
};

function TextMagicCodes(props: any) {
  interface User {
    User_id: Number;
    PhoneNumber: String;
    Code: String;
    Status: String;
    RegistratedDate: String;
    Validation_create_date: String;
    completed: Number;
    attempted: Number;
  }
  const globalContext = useContext(GlobalContext);

  const navigate = useNavigate();

  const userList: JSX.Element[] = [];
  const [Users, setUsers] = useState(globalContext?.preRegistrationData || []);
  const [DefaultUsers, setDefaultUsers] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    displayUsers();
  }, []);

  const displayUsers = () => {
    const timeStamp = Math.floor(Date.now() / 1000);
    console.log(timeStamp);
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Dashboard/registered-users`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setLoading(false);
            const data = res?.data?.Data;
            const reversedData = data?.reverse();
            const indexed = reversedData.map((row: any, index: number) => ({
              id: index + 1,
              ...row,
            }));
            setUsers(indexed);
            localStorage.setItem(
              "preRegistrationData",
              JSON.stringify({
                name: "preRegistrationData",
                value: indexed,
              })
            );
          })
          .catch((error) => {
            alert(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const [ViewDetail, setViewDetail] = useState(false);
  const [UserDetail, setUserDetail] = useState({} as User);
  const [SearchTerm, setSearchTerm] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = globalContext?.preRegistrationData?.filter(
        (user: User) =>
          user?.PhoneNumber.replace(/\s/g, "").includes(term) ||
          user?.Code.toLowerCase().includes(term.toLowerCase())
      );
      setUsers(results || []);
    } else {
      setUsers(globalContext?.preRegistrationData || []);
    }
    setSearchTerm(term);
  };

  const viewUserDetail = (key: any) => {
    setUserDetail(key);
    setViewDetail(true);
  };

  const closeUserDetails = () => {
    setUserDetail({} as User);
    setViewDetail(false);
  };

  const columns: GridColDef[] = [
    {
      field: "Validation_create_date",
      headerName: "Created",
      width: 200,
      disableColumnMenu: true,
    },

    {
      field: "PhoneNumber",
      headerName: "Phone",
      width: 150,
      disableColumnMenu: true,
    },
    { field: "Code", headerName: "Code", width: 100, disableColumnMenu: true },
    {
      field: "RegistratedDate",
      headerName: "Registered",
      width: 200,
      disableColumnMenu: true,
    },

    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: 100,
    //   sortable: false,
    //   disableColumnMenu:true,
    //   renderCell: (params: GridRenderCellParams) => (
    //     params?.row?.RegistratedDate?
    //     <IconButton size="large" color="warning"
    //     onClick={() => viewUserDetail(params?.row)}
    //     >
    //       <Visibility/>
    //     </IconButton>
    //     :<></>
    //   )  },
  ];

  return (
    <React.Fragment>
      <Helmet title="Notification Center" />
      {/* <BigAvatar
                  alt={String(key?.FirstName)}
                  src={
                    "http://meetleeloudashboard.com.s3-website-ap-southeast-2.amazonaws.com/" +
                    String(key?.Avatar)
                  }
                /> */}
      <Grid container style={{ height: "100%" }} spacing={2}>
        <Grid item xs={12} md={8} style={{ backgroundColor: "#fff" }}>
          <Grid container spacing={0} px={2} style={{ height: "5%" }}>
            <Grid item xs={9}>
              <SearchField oc={handleChange} value={SearchTerm} />
            </Grid>
            <Grid item xs={3}>
              <FilterDialog
                data={globalContext?.preRegistrationData}
                callback={setUsers}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} px={2} style={{ height: "95%" }}>
            <DataGrid
              rows={Users || []}
              columns={columns}
              loading={Loading}
              getRowId={(row) => row.id}
              onRowClick={(data: any) => {
                data?.row?.User_id
                  ? viewUserDetail(data?.row)
                  : closeUserDetails();
                setOpenDrawer(true);
              }}
              // checkboxSelection
              // disableSelectionOnClick
              // onSelectionModelChange={handleSelectionChange}
            />
          </Grid>
        </Grid>
        <Drawer
          anchor={"right"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          sx={{ display: { xs: "block", sm: "block", md: "none" } }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              flexDirection: "row",
              overflowY: "auto", // Enable vertical scrolling
              height: "100%", // Take full height of the parent
            }}
            spacing={2}
          >
            <Grid item xs={12}>
              {ViewDetail ? (
                <UserNetworkTabs data={UserDetail} />
              ) : (
                <Card sx={{ width: "100%" }} pt={0}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", justifyContent: "center" }}
                      p={5}
                    >
                      <CardContent>
                        <Typography variant="h5" color={grey[500]}>
                          {stringData.info.userViewDetail}
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Grid>
          </Grid>
        </Drawer>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            flexDirection: "row",
            overflowY: "auto", // Enable vertical scrolling
            height: "100%", // Take full height of the parent
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            {ViewDetail ? (
              <UserNetworkTabs data={UserDetail} />
            ) : (
              <Card sx={{ width: "100%" }} pt={0}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                    p={5}
                  >
                    <CardContent>
                      <Typography variant="h5" color={grey[500]}>
                        {stringData.info.userViewDetail}
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TextMagicCodes;
