import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@tanstack/react-query";
import { api } from "../../../api/UseApi";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";

import Actions from "./Actions";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import Stats from "./Stats";
import SuccessfulSOS from "./SuccessfulSOS";
import WorldMap from "./WorldMap";
import { BASE_URL, GET_REGISTERED_USER } from "../../../api/ApiEndPoints";
import { GlobalContext } from "../../../contexts/GlobalContext";
const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const globalContext = useContext(GlobalContext);
  const saveLocalData = (name: string, value: {}) => {
    localStorage.setItem(name, JSON.stringify({ name: name, value: value }));
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://test-adminapi.meetleelou.com/api/Dashboard/registered-users-count",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const count = response.data?.Count.toString() || "0";

      globalContext?.updateCachedData({
        name: "totalRegisteredUsers",
        value: count,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getDownloads = async () => {
    try {
      const response = await axios.get(
        "https://test-adminapi.meetleelou.com/api/Dashboard/registered-users-mobiletype",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const androidCount = response.data?.Android?.toString() || "0";
      const iosCount = response.data?.Ios?.toString() || "0";

      globalContext?.updateCachedData({
        name: "totalAndroidDownloads",
        value: androidCount,
      });
      globalContext?.updateCachedData({
        name: "totalIOSDownloads",
        value: iosCount,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    getDownloads();
  }, ["get-initially"]);

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <Grid container spacing={6}>
        <Grid item xs={4}>
          <Stats
            title="Total Registered Users"
            amount={globalContext?.cachedData.totalRegisteredUsers || "0"}
            chip=""
            percentagetext="246K"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={4}>
          <Stats
            title="IOS Downloads"
            amount={globalContext?.cachedData.totalIOSDownloads || "0"}
            chip="Annual"
            percentagetext="-5%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={4}>
          <Stats
            title="Android Downloads"
            amount={globalContext?.cachedData.totalAndroidDownloads || "0"}
            chip="Monthly"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <BarChart />
        </Grid>
      </Grid>
      <Grid container spacing={6} direction="row">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid item xs={12}>
            {/* <LeelouDownloads td={iosDownloads + androidDownloads} /> */}
          </Grid>
          <Grid item xs={12}>
            <SuccessfulSOS />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid item xs={12}>
            <WorldMap />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
