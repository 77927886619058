import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import styled from "styled-components/macro";

import stringData from "../stringFile";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});

const FilterModal = ({ data, callback }: any) => {
  const [filters, setFilters] = useState({
    country: "",
    deviceType: "",
    friendsCount: "",
    guardiansCount: "",
    dependantsCount: "",
    hasPendingInvites: null,
    hasActiveSOS: null,
    loginStatus: null,
    emailVerifiedStatus: null,
    createdDateRange: [null, null],
    lastOnlineRange: [null, null],
  });

  const handleFilterChange = (key: any, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClearFilters = () => {
    callback(data);
    handleClose();
    setFilters({
      country: "",
      deviceType: "",
      friendsCount: "",
      guardiansCount: "",
      dependantsCount: "",
      hasPendingInvites: null,
      hasActiveSOS: null,
      loginStatus: null,
      emailVerifiedStatus: null,
      createdDateRange: [null, null],
      lastOnlineRange: [null, null],
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  interface User {
    Avatar: String;
    Country: String;
    CreatedDate: Date;
    DeviceType: String;
    DisplayName: String;
    Email: String;
    FirstName: String;
    LastName: String;
    LastOnline: Date;
    LastUpdate: String;
    Latitude: String;
    Longitude: String;
    MobileCountryCode: String;
    MobileNumber: String;
    FriendsCount: Number;
    GuardiansCount: Number;
    DependantsCount: Number;
    HasActiveSOS: Boolean;
    HasPendingInvites: Boolean;
    IsLoggedOut: Boolean;
    EmailVerifiedStatus: Boolean;
    UserId: String;
  }

  const onApply = () => {
    const filteredUsers = data.filter((user: User) => {
      // Country filter
      const country = filters.country
        ? user.MobileCountryCode === filters.country
        : true;

      const deviceType = filters.deviceType
        ? user.DeviceType === filters.deviceType
        : true;
      const friendsCount =
        filters.friendsCount !== ""
          ? user.FriendsCount === parseInt(filters.friendsCount)
          : true;
      const guardiansCount =
        filters.guardiansCount !== ""
          ? user.GuardiansCount === parseInt(filters.guardiansCount)
          : true;
      const dependantsCount =
        filters.dependantsCount !== ""
          ? user.DependantsCount === parseInt(filters.dependantsCount)
          : true;

      const hasPendingInvites =
        filters.hasPendingInvites !== null
          ? user.HasPendingInvites === filters.hasPendingInvites
          : true;
      const hasActiveSOS =
        filters.hasActiveSOS !== null
          ? user.HasActiveSOS === filters.hasActiveSOS
          : true;

      const loginStatus =
        filters.loginStatus !== null
          ? user.IsLoggedOut === filters.loginStatus
          : true;

      const emailVerified =
        filters.emailVerifiedStatus !== null
          ? user.EmailVerifiedStatus === filters.emailVerifiedStatus
          : true;

      let createdDateOn = true,
        lastOnlinOn = true;

      if (
        filters.createdDateRange[0] !== null &&
        filters.createdDateRange[1] !== null
      ) {
        const createdDate = new Date(user.CreatedDate);
        const [startDate, endDate] = filters.createdDateRange.map((d) =>
          d ? new Date(d) : null
        );
        if (
          (startDate && createdDate < startDate) ||
          (endDate && createdDate > endDate)
        ) {
          createdDateOn = false;
        }
      }

      if (
        filters.lastOnlineRange[0] !== null &&
        filters.lastOnlineRange[1] !== null
      ) {
        const lastOnlineDate = new Date(user.LastOnline);
        const [startDate, endDate] = filters.lastOnlineRange.map((d) =>
          d ? new Date(d) : null
        );
        if (
          (startDate && lastOnlineDate < startDate) ||
          (endDate && lastOnlineDate > endDate)
        ) {
          lastOnlinOn = false;
        }
      }
      if (
        country &&
        deviceType &&
        friendsCount &&
        dependantsCount &&
        guardiansCount &&
        hasPendingInvites &&
        hasActiveSOS &&
        loginStatus &&
        emailVerified &&
        createdDateOn &&
        lastOnlinOn
      ) {
        return true;
      } else {
        return false;
      }
    });

    callback(filteredUsers);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="warning"
        aria-haspopup="true"
        fullWidth={true}
      >
        {stringData.filter.filter}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Search Filters"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Table>
              <TableBody>
                {/* Country */}
                {/* <TableRow>
                  <StyledTableCell align="left">Country</StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={filters.country}
                        onChange={(e) =>
                          handleFilterChange("country", e.target.value)
                        }
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          Select country
                        </MenuItem>
                        <MenuItem value="Australia">Australia</MenuItem>
                        <MenuItem value="USA">USA</MenuItem>
                        <MenuItem value="New Zealand">New Zealand</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow> */}

                {/* Device Type */}
                <TableRow>
                  <StyledTableCell align="left">Device Type</StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={filters.deviceType}
                        onChange={(e) =>
                          handleFilterChange("deviceType", e.target.value)
                        }
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          Select device type
                        </MenuItem>
                        <MenuItem value="IOS">IOS</MenuItem>
                        <MenuItem value="Android">Android</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Friends Count */}
                <TableRow>
                  <StyledTableCell align="left">Friends Count</StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <TextField
                        type="number"
                        value={filters.friendsCount}
                        onChange={(e) =>
                          handleFilterChange("friendsCount", e.target.value)
                        }
                      />
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Guardians Count */}

                <TableRow>
                  <StyledTableCell align="left">
                    Guardians Count
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <TextField
                        type="number"
                        value={filters.guardiansCount}
                        onChange={(e) =>
                          handleFilterChange("guardiansCount", e.target.value)
                        }
                      />
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Dependants Count */}

                <TableRow>
                  <StyledTableCell align="left">
                    Dependants Count
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <TextField
                        type="number"
                        value={filters.dependantsCount}
                        onChange={(e) =>
                          handleFilterChange("dependantsCount", e.target.value)
                        }
                      />
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Has Pending Invites */}
                <TableRow>
                  <StyledTableCell align="left">
                    <Typography>Has Pending Invites</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={filters.hasPendingInvites ? 1 : 0}
                        onChange={(e) =>
                          handleFilterChange(
                            "hasPendingInvites",
                            e.target.value === 1 ? true : false
                          )
                        }
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          Select status
                        </MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Has Active SOS */}
                <TableRow>
                  <StyledTableCell align="left">
                    <Typography>Has Active SOS</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={filters.hasActiveSOS ? 1 : 0}
                        onChange={(e) =>
                          handleFilterChange(
                            "hasActiveSOS",
                            e.target.value === 1 ? true : false
                          )
                        }
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          Select status
                        </MenuItem>
                        <MenuItem value={1}>Yes</MenuItem>
                        <MenuItem value={0}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Login Status */}
                <TableRow>
                  <StyledTableCell align="left">
                    <Typography>Login Status</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={filters.loginStatus ? 1 : 0}
                        onChange={(e) =>
                          handleFilterChange(
                            "loginStatus",
                            e.target.value === 1 ? true : false
                          )
                        }
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          Select login status
                        </MenuItem>
                        <MenuItem value={1}>Logged In</MenuItem>
                        <MenuItem value={0}>Logged Out</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Email Verified */}
                <TableRow>
                  <StyledTableCell align="left">
                    <Typography>Email Verified</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={filters.emailVerifiedStatus ? 1 : 0}
                        onChange={(e) =>
                          handleFilterChange(
                            "emailVerifiedStatus",
                            e.target.value === 1 ? true : false
                          )
                        }
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value="" disabled>
                          Select verification status
                        </MenuItem>
                        <MenuItem value={1}>Verified</MenuItem>
                        <MenuItem value={0}>Not Verified</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Created Date */}

                <TableRow>
                  <StyledTableCell align="left">
                    <Typography>Created Date Range</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <TextField
                        type="date"
                        value={filters.createdDateRange[0]}
                        onChange={(e) =>
                          handleFilterChange("createdDateRange", [
                            e.target.value,
                            filters.createdDateRange[1],
                          ])
                        }
                      />
                      <TextField
                        type="date"
                        value={filters.createdDateRange[1]}
                        onChange={(e) =>
                          handleFilterChange("createdDateRange", [
                            filters.createdDateRange[0],
                            e.target.value,
                          ])
                        }
                      />
                    </FormControl>
                  </StyledTableCell>
                </TableRow>

                {/* Last Online */}

                <TableRow>
                  <StyledTableCell align="left">
                    <Typography>Last Online Range</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <TextField
                        type="date"
                        value={filters.lastOnlineRange[0]}
                        onChange={(e) =>
                          handleFilterChange("lastOnlineRange", [
                            e.target.value,
                            filters.lastOnlineRange[1],
                          ])
                        }
                      />
                      <TextField
                        type="date"
                        value={filters.lastOnlineRange[1]}
                        onChange={(e) =>
                          handleFilterChange("lastOnlineRange", [
                            filters.lastOnlineRange[0],
                            e.target.value,
                          ])
                        }
                      />
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearFilters} variant="outlined" color="error">
            Clear Filters
          </Button>
          <Button onClick={onApply} variant="contained" color="warning">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterModal;
