import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Slider,
  Typography,
  CircularProgress,
  styled,
} from "@material-ui/core";

import { PlayCircle, PauseCircle } from "@material-ui/icons";
interface AudioPlayerProps {
  playList: string[];
}

interface AudioState {
  isPlaying: boolean;
  currentIndex: number;
  finishedPlaying: boolean;
  loading: boolean;
  position: number;
  duration: number;
}

// Styled components
const PlayButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  border: "2px solid #F89449",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
  "& .MuiSvgIcon-root": {
    color: "#F89449",
  },
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#F89449",
  height: 4,
  "& .MuiSlider-thumb": {
    width: 12,
    height: 12,
    backgroundColor: "#rgb(248, 148, 73)",
    "&:hover, &.Mui-focusVisible": {
      boxShadow: "0px 0px 0px 8px rgba(248, 148, 73, 0.16)",
    },
  },
  "& .MuiSlider-rail": {
    opacity: 0.28,
  },
}));

const AudioPlayer: React.FC<AudioPlayerProps> = ({ playList }) => {
  const [state, setState] = useState<AudioState>({
    isPlaying: false,
    currentIndex: 0,
    finishedPlaying: false,
    loading: true,
    position: 0,
    duration: 0,
  });

  const audioElements = useRef<HTMLAudioElement[]>([]);
  const currentAudioIndex = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    if (playList?.length) {
      setupPlayer();
    }

    return () => {
      cleanupAudio();
    };
  }, [playList]);

  const cleanupAudio = () => {
    audioElements.current.forEach((audio) => {
      audio.pause();
      audio.removeEventListener("ended", handleAudioEnd);
    });
    audioElements.current = [];
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    setState((prev) => ({
      ...prev,
      currentIndex: 0,
      isPlaying: false,
      position: 0,
    }));
  };

  const handleAudioEnd = () => {
    const nextIndex = currentAudioIndex.current + 1;
    if (nextIndex < audioElements.current.length) {
      currentAudioIndex.current = nextIndex;
      playAudioAtIndex(nextIndex);
    } else {
      setState((prev) => ({
        ...prev,
        isPlaying: false,
        position: 0,
        finishedPlaying: true,
      }));
    }
  };

  const setupPlayer = () => {
    try {
      audioElements.current = playList.map((url) => {
        const audio = new Audio(url);
        audio.addEventListener("ended", handleAudioEnd);
        return audio;
      });

      let totalDuration = 0;
      const loadPromises = audioElements.current.map(
        (audio) =>
          new Promise<void>((resolve) => {
            audio.addEventListener("loadedmetadata", () => {
              totalDuration += audio.duration;
              resolve();
            });
            audio.addEventListener("error", (e) => {
              console.error("Error loading audio:", e);
              resolve();
            });
          })
      );

      Promise.all(loadPromises).then(() => {
        setState((prev) => ({
          ...prev,
          loading: false,
          duration: totalDuration,
        }));
      });
    } catch (error) {
      console.error("Error setting up audio player:", error);
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const playAudioAtIndex = (index: number) => {
    audioElements.current.forEach((audio, i) => {
      if (i === index) {
        audio.play().catch(console.error);
      } else {
        audio.pause();
        audio.currentTime = 0;
      }
    });
  };

  const handlePlayPause = () => {
    if (state.isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const playAudio = async () => {
    try {
      playAudioAtIndex(currentAudioIndex.current);
      setState((prev) => ({ ...prev, isPlaying: true }));
      startProgressUpdate();
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  };

  const pauseAudio = () => {
    try {
      audioElements.current[currentAudioIndex.current]?.pause();
      setState((prev) => ({ ...prev, isPlaying: false }));
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    } catch (error) {
      console.error("Error pausing audio:", error);
    }
  };

  const startProgressUpdate = () => {
    const updateProgress = () => {
      if (state.isPlaying && audioElements.current.length > 0) {
        let position = 0;
        for (let i = 0; i < currentAudioIndex.current; i++) {
          position += audioElements.current[i].duration;
        }
        position +=
          audioElements.current[currentAudioIndex.current].currentTime;

        setState((prev) => ({
          ...prev,
          position: position,
        }));

        animationFrameRef.current = requestAnimationFrame(updateProgress);
      }
    };
    updateProgress();
  };

  const handleSliderChange = (_event: Event, value: number | number[]) => {
    const newPosition = typeof value === "number" ? value : value[0];
    let targetIndex = 0;
    let accumulatedDuration = 0;

    for (let i = 0; i < audioElements.current.length; i++) {
      const audioDuration = audioElements.current[i].duration;
      if (accumulatedDuration + audioDuration > newPosition) {
        targetIndex = i;
        break;
      }
      accumulatedDuration += audioDuration;
    }

    const targetTime = newPosition - accumulatedDuration;
    currentAudioIndex.current = targetIndex;
    audioElements.current.forEach((audio, index) => {
      if (index === targetIndex) {
        audio.currentTime = targetTime;
      } else {
        audio.currentTime = 0;
      }
    });

    setState((prev) => ({
      ...prev,
      position: newPosition,
    }));

    if (state.isPlaying) {
      pauseAudio();
      playAudio();
    }
  };

  const formatDuration = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    if (hours > 0) {
      return `${hours}h : ${minutes.toString().padStart(2, "0")}m`;
    }
    return `${minutes.toString().padStart(2, "0")}m :${secs
      .toString()
      .padStart(2, "0")}s`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        px: 2.5,
        py: 1.5,
        alignItems: "center",
        gap: 2,
        borderRadius: 10,
        borderWidth: 0.5,
        borderColor: "lightgrey",
      }}
    >
      <PlayButton
        disabled={state.loading}
        onClick={handlePlayPause}
        size="small"
      >
        {!state.loading ? (
          state.isPlaying ? (
            <PauseCircle color="warning" />
          ) : (
            <PlayCircle color="warning" />
          )
        ) : (
          <CircularProgress size={24} sx={{ color: "#F89449" }} />
        )}
      </PlayButton>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 0.5,
        }}
      >
        <StyledSlider
          disabled={state.loading}
          min={0}
          max={state.duration || 0}
          step={0.1}
          value={state.position}
          onChange={handleSliderChange}
          onMouseDown={() => pauseAudio()}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 0.5,
          }}
        >
          <Typography variant="caption" sx={{ color: "grey" }}>
            {state.loading
              ? "0m : 0s"
              : formatDuration(Math.floor(state.position))}
          </Typography>
          <Typography variant="caption" sx={{ color: "grey" }}>
            {state.loading
              ? "0m : 0s"
              : formatDuration(Math.floor(state.duration))}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AudioPlayer;
