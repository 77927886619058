import React, { useContext } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { spacing, SpacingProps } from "@material-ui/system";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { GlobalContext } from "../../contexts/GlobalContext";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const timeOut = (time: number) => new Promise((res) => setTimeout(res, time));

const initialValues = {
  title: "",
  description: "",
  jData: {},
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

function PushNotificationForm(props: any) {
  const selectedRows = props?.selectedRows;
  const globalContext = useContext(GlobalContext);

  const sendPushNotification = useMutation(
    ["sendNotification"],
    async (data) =>
      await axios
        .post("https://test-adminapi.meetleelou.com/api/Notifications", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response: any) => {
          console.log(response.data);
          return response?.data;
        })
        .catch((e) => {
          return e;
        }),
    {
      onSuccess: (response: any) => {
        if (response?.Success) {
          globalContext?.setDisplayQuickAlert({
            open: true,
            message: "Push notification susccessfully sent to selected users.",
          });
          props?.setOpen(false);
        } else {
          globalContext?.setDisplayQuickAlert({
            open: true,
            message: "Error occurred while sending push notifications.",
          });
        }
      },
      onError: () => {
        globalContext?.setDisplayQuickAlert({
          open: true,
          message: "Error occurred while sending push notifications.",
        });
      },
    }
  );

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const data = {
        ...values,
        receiverIds: selectedRows,
        jData: { type: "SystemNotification" },
      };
      sendPushNotification?.mutate(data);
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card p={5}>
          <CardContent>
            <Typography variant="body2" gutterBottom>
              Send push notifications to <b>{selectedRows?.length}</b> users.
            </Typography>

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Push notification sent successfully!
              </Alert>
            )}

            {sendPushNotification?.isLoading ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} py={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="title"
                        label="Title"
                        value={values.title}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        style={{ width: "100%" }}
                        inputProps={{
                          maxLength: 40,
                        }}
                      />
                      <FormHelperText>
                        Maximum 40 characters allowed.
                      </FormHelperText>
                    </FormControl>
                  </Grid>{" "}
                  <Grid item xs={12} py={2}>
                    <FormControl fullWidth>
                      <TextField
                        name="description"
                        label="Description"
                        value={values.description}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        multiline={true}
                        rows={4}
                        my={2}
                        style={{ width: "100%" }}
                        inputProps={{
                          maxLength: 240,
                        }}
                      />
                      <FormHelperText>
                        Maximum 240 characters allowed.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="warning"
                  mt={3}
                  disabled={
                    sendPushNotification?.isLoading ||
                    values.title == "" ||
                    values.description == ""
                  }
                  size={"large"}
                >
                  {sendPushNotification?.isLoading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Send Notification"
                  )}
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default PushNotificationForm;
