import React, { useState, useEffect, useContext } from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { rgba } from "polished";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import axios from "axios";
import { GlobalContext } from "../../../contexts/GlobalContext";
const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

const BarChart = ({ theme }: ThemeProps<Theme>) => {
  const secondDatasetColor = rgba(theme.palette.success.light, 0.7);
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    fetchRegisteredUserCount();
  }, ["get-initially"]);

  const fetchRegisteredUserCount = async () => {
    try {
      const response = await axios.get(
        "https://test-adminapi.meetleelou.com/api/Dashboard/user-count-date",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response?.data?.Status) {
        const data = response?.data?.Data;
        const dateRangeData = generateDateRangeData(data);
        const chartData = {
          labels: dateRangeData?.map((item: any) => item.Month),
          datasets: [
            {
              type: "line",
              label: "User Registration",
              backgroundColor: secondDatasetColor,
              borderColor: secondDatasetColor,
              hoverBackgroundColor: secondDatasetColor,
              hoverBorderColor: secondDatasetColor,
              data: dateRangeData?.map((item: any) => item.Count),
              barPercentage: 0.5,
              categoryPercentage: 0.5,
              borderRadius: 6,
              options: {
                interaction: {
                  intersect: false,
                  mode: "index",
                },
              },
            },
          ],
        };
        globalContext?.updateCachedData({
          name: "totalRegisteredUsersByMonth",
          value: chartData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateDateRangeData = (data: any) => {
    const startDate = new Date("2024-01-01");
    const endDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Create a map to hold monthly data
    const monthMap = new Map();

    // Aggregate counts by month
    data.forEach((item: any) => {
      const date = new Date(item.Date);
      const yearMonth = `${date.getFullYear()}-${date.getMonth()}`; // Format as YYYY-MM (month is 0-indexed)
      const currentCount = monthMap.get(yearMonth) || 0;
      monthMap.set(yearMonth, currentCount + item.Count);
    });

    // Generate the result array
    const monthlyData = [];
    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setMonth(date.getMonth() + 1)
    ) {
      const yearMonth = `${date.getFullYear()}-${date.getMonth()}`;
      const monthName = monthNames[date.getMonth()];
      monthlyData.push({
        Month: monthName,
        Count: monthMap.get(yearMonth) || 0,
      });
    }

    return monthlyData;
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        align: "center", //start for left align'
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          fontColor: theme.palette.text.secondary,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: true, // This hides the x-axis labels
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="User Registration"
      />

      <CardContent>
        <ChartWrapper>
          <Chart
            type="bar"
            data={globalContext?.cachedData?.totalRegisteredUsersByMonth}
            options={options}
          />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
