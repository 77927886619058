import React, { createContext, useState, useEffect, ReactNode } from "react";
import { SosType } from "../pages/sos/SosTypes";

interface ActionButtonProps {
  name: string;
  onClick: () => void;
}

interface DialogProps {
  open: boolean;
  title: string;
  description: string;
  buttons: ActionButtonProps[];
}

interface QuickAlertProps {
  message: string;
  open: boolean;
}

interface CachedDataProps {
  totalRegisteredUsers: string;
  totalAndroidDownloads: string;
  totalIOSDownloads: string;
  totalRegisteredUsersByMonth: {};
  userRegistrationByCountry: [];
  successfulSOSData: {};
  usersList: [];
  admninsList: [];
}

type AuthContextType = {
  selectedSos: SosType;
  displayDialog: DialogProps;
  setDisplayDialog: React.Dispatch<React.SetStateAction<DialogProps>>;
  displayQuickAlert: QuickAlertProps;
  setDisplayQuickAlert: React.Dispatch<React.SetStateAction<QuickAlertProps>>;
  cachedData: CachedDataProps;
  setCachedData: React.Dispatch<React.SetStateAction<CachedDataProps>>;
  updateCachedData: any;
  preRegistrationData: [];
  setPreRegistrationData: React.Dispatch<React.SetStateAction<[]>>;
  sosAlertsData: [];
  setSosAlertsData: React.Dispatch<React.SetStateAction<[]>>;
  notificationCenterData: [];
  setNotificationCenterData: React.Dispatch<React.SetStateAction<[]>>;
};

const GlobalContext = createContext<AuthContextType | null>(null);

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [selectedSos, setSelectedSos] = useState<SosType>({
    Id: 0,
    Guid: "",
    CreateDate: "",
    LastUpdate: "",
    ConversationId: "",
    AudioId: "",
    LocationEnabled: 1,
    MicEnabled: 1,
    NotificationsEnabled: 1,
    EndDate: "",
    EndRequest: "",
    EndReason: "",
    Status: "",
    VictimUserDetail: {
      User: {
        UserId: 0,
        DisplayName: "",
      },
      Activity: {
        LastActive: "",
        Battery: 0,
        Latitude: 0,
        Longitude: 0,
        Accuracy: 0,
      },
      Contact: {
        Country: "",
        Number: "",
        Dialable: "",
      },
    },
    GuardianDetails: {
      User: {
        UserId: 1,
        DisplayName: "",
        Email: "",
        Birthday: "",
        Address: "",
        Suburb: "",
        Postcode: "",
        State: "",
        Country: "",
        ShareLocation: "",
        Gender: "",
        IsOffline: true,
      },
      Activity: {
        LastUpdate: "",
        LastOnline: "",
        Battery: 0,
        Latitude: 0,
        Longitude: 0,
        Accuracy: 0,
        ZoneName: "",
      },
      Contact: {
        MobileId: 0,
        GuardianMobileCountry: "",
        Number: "",
        Dialable: "",
      },
    },
  });

  const [cachedData, setCachedData] = useState<CachedDataProps>({
    totalRegisteredUsers: "0",
    totalAndroidDownloads: "0",
    totalIOSDownloads: "0",
    totalRegisteredUsersByMonth: {},
    userRegistrationByCountry: [],
    successfulSOSData: {},
    usersList: [],
    admninsList: [],
  });

  const [preRegistrationData, setPreRegistrationData] = useState([] as any);
  const [sosAlertsData, setSosAlertsData] = useState([] as any);
  const [notificationCenterData, setNotificationCenterData] = useState(
    [] as any
  );

  const [displayDialog, setDisplayDialog] = useState<DialogProps>({
    open: false,
    title: "",
    description: "",
    buttons: [],
  });

  const [displayQuickAlert, setDisplayQuickAlert] = useState<QuickAlertProps>({
    message: "",
    open: false,
  });

  const getLocalData = () => {
    const data = localStorage.getItem("cachedData");
    if (data) {
      const obj = JSON.parse(data);
      setCachedData(obj?.value);
    } else {
      localStorage.setItem(
        "cachedData",
        JSON.stringify({ name: "cachedData", value: cachedData })
      );
    }
  };

  const updateCachedData = ({ name, value }: { name: string; value: any }) => {
    setCachedData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      localStorage.setItem(
        "cachedData",
        JSON.stringify({ name: "cachedData", value: updatedData })
      );
      return updatedData;
    });
  };

  const getPreRegistrationData = () => {
    const data = localStorage.getItem("preRegistrationData");
    if (data) {
      const obj = JSON.parse(data);
      setPreRegistrationData(obj?.value || []);
    }
  };

  const getSosAlertData = () => {
    const data = localStorage.getItem("sosAlertsData");
    if (data) {
      const obj = JSON.parse(data);
      setSosAlertsData(obj?.value || []);
    }
  };

  const getNotificationCenterData = () => {
    const data = localStorage.getItem("notificationCenterData");
    if (data) {
      const obj = JSON.parse(data);
      setNotificationCenterData(obj?.value || []);
    }
  };

  useEffect(() => {
    getPreRegistrationData();
    getLocalData();
    getSosAlertData();
    getNotificationCenterData();
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        selectedSos,
        displayDialog,
        setDisplayDialog,
        displayQuickAlert,
        setDisplayQuickAlert,
        cachedData,
        setCachedData,
        updateCachedData,
        preRegistrationData,
        setPreRegistrationData,
        sosAlertsData,
        setSosAlertsData,
        notificationCenterData,
        setNotificationCenterData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
