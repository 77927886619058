import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { backgrounds, darken } from "polished";
import { grey, yellow, red, green, purple } from "@material-ui/core/colors";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SosDetails from "./SosDetails";
import stringData from "../stringFile";
import { AuthContext } from "../../contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import Table from "react-bootstrap/Table";
import {
  Avatar,
  Button,
  CircularProgress,
  CardContent,
  Chip,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import Actions from "./Actions";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter } from "react-feather";
import {
  ArrowDropUp as Ascending,
  ArrowDropDown as Descending,
  Search as SearchIcon,
  FilterList as FilterIcon,
  ArrowBack as LeftArrow,
  ArrowRight,
  ArrowLeft,
  Clear,
} from "@material-ui/icons";
import "react-resizable/css/styles.css"; // Import the CSS for proper styling

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const BigAvatar = styled(Avatar)`
  width: 15px;
  height: 15px;
`;

const CD = styled(MuiCard)(spacing);
const Card = styled(CD)`
  cursor: pointer;
`;

const FilterGrid = styled(Grid)`
  background-color: #efefef;
  border-radius: 5px;
`;

const WhiteGrid = styled(Grid)`
  background-color: #fff;
  border-radius: 5px;
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: 10px;
    width: 100%;
  }
`;

function SOS() {
  interface Sos {
    Id: Number;
    Guid: String;
    CreateDate: String;
    LastUpdate: String;
    ConversationId: String;
    AudioId: String;
    LocationEnabled: 1;
    MicEnabled: 1;
    NotificationsEnabled: 1;
    EndDate: String;
    EndRequest: String;
    EndReason: String;
    Status: String;
    VictimUserDetail: {
      User: {
        UserId: Number;
        DisplayName: String;
      };
      Activity: {
        LastActive: String;
        Battery: Number;
        Latitude: Number;
        Longitude: Number;
        Accuracy: Number;
      };
      Contact: {
        Country: String;
        Number: String;
        Dialable: String;
      };
    };
    GuardianDetails: {
      User: {
        UserId: 1;
        DisplayName: String;
        Email: String;
        Birthday: String;
        Address: String;
        Suburb: String;
        Postcode: String;
        State: String;
        Country: String;
        ShareLocation: String;
        Gender: String;
        IsOffline: Boolean;
      };
      Activity: {
        LastUpdate: String;
        LastOnline: String;
        Battery: Number;
        Latitude: Number;
        Longitude: Number;
        Accuracy: Number;
        ZoneName: String;
      };
      Contact: {
        MobileId: Number;
        GuardianMobileCountry: String;
        Number: String;
        Dialable: String;
      };
    };
  }
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [viewDetail, setOpen] = React.useState("none");
  const handleClickOpen = () => {
    setOpen("block");
  };
  const [type, setType] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => {
    setOpen("none");
    setAnchorEl(null);
    setAnchorE(null);
  };
  const [SosData, setSosData] = React.useState([]);
  const [DefaultSosData, setDefaultSosData] = React.useState([]);

  useEffect(() => {
    const url = "http://54.253.14.176/chatHub";
    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => {
        connection
          .invoke("JoinConversationGroup", "NEWSOS")
          .then(() => console.log("Connected to :", "NEWSOS"))
          .catch((err) => console.error(err));
      })
      .then(() => {
        console.log("NEWSOS HUB SUCCESSFULLY CONNECTED");
      })
      .catch((err) => console.error("Connection failed: ", err));

    connection.on("ReceiveMessage", (user, message) => {
      alert("NEW SOS!");
      refetch();
      playAlertSound();
    });

    return () => {
      connection.stop();
    };
  }, []);

  const playAlertSound = () => {
    const audio = new Audio(require("../../assets/sound/alarm.mp3")); // Ensure this path is correct
    audio.play();
  };

  const { isLoading, refetch } = useQuery(
    ["get-sos", type, page],
    async () =>
      axios
        .get(
          `https://test-adminapi.meetleelou.com/api/Sos/SOSList?Type${type}&CurrentPage=${page}&PageSize=${50}`,
          { headers: { Authorization: `Bearer ${authContext?.auth?.token}` } }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        }),
    {
      enabled: true,
      onSuccess: (response: any) => {
        if (response?.Status) {
          setSosData(response?.Data.reverse());
          setDefaultSosData(response?.Data);
          setTotalPages(response?.TotalPages);
        }
      },
      onError: () => {
        alert("Error occurred while loading SOS list");
      },
    }
  );

  const [SearchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [SosDetail, setSosDetail] = useState([] as any);
  const [ViewDetail, setViewDetail] = useState(false);
  const [Clicked, setClicked] = useState(false);

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = DefaultSosData.filter((sos: Sos) =>
        sos?.VictimUserDetail?.User?.DisplayName.toLowerCase().includes(
          term.toLowerCase()
        )
      );
      setSosData(results);
    } else {
      setSosData(DefaultSosData);
    }
    setSearchTerm(term);
  };

  const [SortBy, setSortBy] = useState({ name: -1, userId: -1, sos: "All" });

  const [sortByNameType, setsortByNameType] = useState(1);
  const handleSortByName = (type: number) => {
    if (type) {
      let newdata = [].concat(SosData).sort((a: Sos, b: Sos) => {
        return a?.VictimUserDetail?.User?.DisplayName.toLowerCase()[0] <
          b?.VictimUserDetail?.User?.DisplayName.toLowerCase()[0]
          ? -1
          : 1;
      });
      setSosData(newdata);
      setsortByNameType(0);
    } else {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return a?.VictimUserDetail?.User?.DisplayName.toLowerCase()[0] <
          b?.VictimUserDetail?.User?.DisplayName.toLowerCase()[0]
          ? 1
          : -1;
      });
      setSosData(newdata);
      setsortByNameType(1);
    }

    handleClose();
  };

  //Sort the sos list by user id.
  const [sortBySosIdType, setsortBySosIdType] = useState(1);
  const handleSortBySosId = (type: number) => {
    if (!type) {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return a.Id - b.Id;
      });
      setSosData(newdata);
      setsortBySosIdType(1);
    } else {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return b.Id - a.Id;
      });
      setSosData(newdata);
      setsortBySosIdType(0);
    }

    handleClose();
  };

  //Sort the sos list by user id.
  const [sortByIdType, setsortByIdType] = useState(1);
  const handleSortById = (type: number) => {
    if (type) {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return (
          a?.VictimUserDetail?.User?.UserId - b?.VictimUserDetail?.User?.UserId
        );
      });
      setSosData(newdata);
      setsortByIdType(0);
    } else {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        return (
          b?.VictimUserDetail?.User?.UserId - a?.VictimUserDetail?.User?.UserId
        );
      });
      setSosData(newdata);
      setsortByIdType(1);
    }

    handleClose();
  };

  const [sortByDate, setSortByDate] = useState(1);

  const handleSortByDate = (type: number) => {
    if (!type) {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        const dateA: Date = new Date(a?.CreateDate);
        const dateB: Date = new Date(b?.CreateDate);
        return dateA.getTime() - dateB.getTime();
      });
      setSosData(newdata);
      setSortByDate(1);
    } else {
      let newdata = [].concat(SosData).sort((a: any, b: any) => {
        const dateA: Date = new Date(a?.CreateDate);
        const dateB: Date = new Date(b?.CreateDate);
        return dateB.getTime() - dateA.getTime();
      });
      setSosData(newdata);
      setSortByDate(0);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorE, setAnchorE] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE(event.currentTarget);
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const SosCard = (key: Sos) => {
    const created_date = new Date(String(key?.CreateDate));
    return (
      <Grid
        item
        xs={12}
        md={12}
        p={0}
        onClick={() => {
          setSosDetail(key);
          setViewDetail(true);
        }}
      >
        <Card mb={2}>
          <CardContent
            onClick={handleClickOpen}
            sx={{
              padding: "8px !important",
              backgroundColor:
                SosDetail?.Guid == key?.Guid ? "#ffead8" : "#fff",
            }}
          >
            <Grid container style={{ display: "flex", alignItems: "center" }}>
              <Grid
                item
                xs={8}
                alignItems="center"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <BigAvatar
                  alt={String(key?.VictimUserDetail?.User?.DisplayName)}
                  src={String(key?.VictimUserDetail?.User?.DisplayName)}
                />
                <Typography variant="body2" color={grey[500]} pl={2}>
                  {key?.VictimUserDetail?.User?.DisplayName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color={grey[500]}>
                  {`${
                    months[created_date.getMonth() - 1]
                  } ${created_date.getDate()}`}
                  {/* <br></br>
                  {`${created_date.getHours()}:${created_date.getMinutes()} ${
                    created_date.getHours() > 12 ? "PM" : "AM"
                  }`} */}
                </Typography>
                <Grid
                  sx={{
                    height: 10,
                    width: 10,
                    borderRadius: 100,
                    backgroundColor:
                      key?.Status == "PendingCancel"
                        ? "#ffed0038"
                        : key?.Status == "Cancelled"
                        ? "#ff000038"
                        : key?.Status == "Active"
                        ? "#00ff0038"
                        : "grey",
                    marginLeft: 5,
                  }}
                ></Grid>
              </Grid>

              {/* <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {key?.Status == "Active" ? (
                  <Chip
                    size="small"
                    label={stringData.filter.active}
                    color="success"
                  />
                ) : (
                  ""
                )}
                {key?.Status == "PendingCancel" ? (
                  <Chip
                    size="small"
                    label={stringData.filter.pendingCancel}
                    color="warning"
                  />
                ) : (
                  ""
                )}
                {key?.Status == "Cancelled" ? (
                  <Chip
                    size="small"
                    label={stringData.filter.cancelled}
                    color="error"
                  />
                ) : (
                  ""
                )}
               {key.status == "FalseStart" ? (
                  <FalseStartChip
                    size="small"
                    label={stringData.filter.falseStart}
                    style={{ width: "100%" }}
                  />
                ) : (
                  ""
                )} 
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const [width, setWidth] = useState(450);
  return (
    <React.Fragment>
      <Helmet title="SOS Alerts" />
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid item style={{ backgroundColor: "#fff" }} xs={4} md={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container xs={12} pb="10px" spacing={2}>
              <FilterGrid container xs={7}>
                <Grid
                  item
                  xs={10}
                  alignItems="center"
                  style={{ display: "flex", justifyItems: "flex-start" }}
                  pl={2}
                >
                  <SearchIcon color={"disabled"} />
                  <Input
                    placeholder={stringData.inputPlaceholder.sosSearch}
                    onChange={handleChange}
                    value={SearchTerm}
                    sx={{ paddingLeft: "10px" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  alignItems="center"
                  style={{ display: "flex", justifyContent: "right" }}
                  pr={2}
                >
                  <Clear
                    color={"disabled"}
                    style={{ cursor: "pointer" }}
                    onClick={() => (
                      setSosData(DefaultSosData), setSearchTerm("")
                    )}
                  />
                </Grid>
              </FilterGrid>
              <Grid item xs={1}></Grid>

              <WhiteGrid item xs={4}>
                <Actions callback={setType} />
              </WhiteGrid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12} md={12} p={0}>
                <Grid
                  container
                  style={{ display: "flex", alignItems: "center" }}
                  p={2}
                >
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSortByName(sortByNameType)}
                  >
                    <Typography
                      variant="body2"
                      color={grey[800]}
                      alignItems="center"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {stringData.tableHeader.name}{" "}
                      {sortByNameType ? (
                        <Ascending></Ascending>
                      ) : (
                        <Descending></Descending>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ display: "flex", justifyContent: "center" }}
                    onClick={() => handleSortByDate(sortByDate)}
                  >
                    {stringData.tableHeader.createdDate}
                    {sortByDate ? (
                      <Ascending></Ascending>
                    ) : (
                      <Descending></Descending>
                    )}{" "}
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography variant="body2" color={grey[800]}>
                      {stringData.tableHeader.sosStatus}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              {isLoading ? (
                <Grid
                  item
                  xs={8}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                  alignItems="center"
                >
                  <CircularProgress color="warning" size={30} />
                  Getting SOS List
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid container xs={12}>
              <Grid
                container
                xs={12}
                direction="row"
                mt={0}
                sx={{
                  maxHeight: "80vh",
                  overflowY: "scroll",
                }}
              >
                {SosData.map(SosCard)}
              </Grid>
            </Grid>
            <Grid container xs={12} justifyContent="center" py={3}>
              {isLoading ? (
                <></>
              ) : (
                <Grid
                  item
                  xs={8}
                  sm={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignContent: "center",
                  }}
                  alignItems="center"
                >
                  <Button
                    disabled={page == 1}
                    variant="outlined"
                    size="small"
                    onClick={() => setPage(page - 1)}
                  >
                    <ArrowLeft />
                  </Button>
                  <Chip
                    size="small"
                    label={`${page} of ${totalPages}`}
                    style={{
                      backgroundColor: "#efefef",
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                  <Button
                    disabled={page < totalPages ? false : true}
                    variant="outlined"
                    size="small"
                    onClick={() => setPage(page + 1)}
                  >
                    <ArrowRight />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item p={5} xs={8} md={10}>
          {ViewDetail ? (
            // <SosUserDetails data={SosDetail?.Guid}></SosUserDetails>
            <SosDetails id={SosDetail?.Guid} />
          ) : (
            <Card sx={{ width: "100%" }} pt={0}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                  p={5}
                >
                  <CardContent>
                    <Typography variant="h5" color={grey[500]}>
                      {stringData.info.sosViewDetail}
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SOS;
