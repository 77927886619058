import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { spacing } from "@material-ui/system";
import { green, grey } from "@material-ui/core/colors";

import {
  Box as MuiBox,
  Chip,
  Divider,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@material-ui/core";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { SidebarItemsType } from "../../types/sidebar";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: #233044 !important;
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

export type SidebarProps = {
  titleCallback: any;
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  titleCallback,
  ...rest
}) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" sx={{ height: 100 }}>
        {/* <BrandIcon /> */}
        <img
          height={"30px"}
          width={"30px"}
          alt="Leelou Logo"
          src="/static/img/avatars/leelou-logo.png"
        />
        <Box ml={3} sx={{ color: "#fff" }}>
          Leelou
        </Box>
        {/* <BrandChip label="PRO" /> */}
      </Brand>
      <SidebarNav items={items} titleCallback={titleCallback} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
