import React, { useEffect, useState } from "react";

import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, yellow, red, green } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import SosUserDetails from "./SosUserDetail";
import SosMap from "./SosMap";
import SosGuards from "./SosGuards";
import SosInteractions from "./SosInteractions";
import SosChat from "./SosChat";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css"; // Import the CSS for proper styling
import {
  Avatar,
  Button,
  Box,
  CardContent,
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import { Favorite as FavoriteIcon } from "@material-ui/icons";
import { makeSelectable } from "material-ui";
import Loader from "../../components/Loader";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;

const PendingChip = styled(Chip)({
  backgroundColor: red[100],
});
const ActiveChip = styled(Chip)({
  backgroundColor: green[100],
});
const CancelledChip = styled(Chip)({
  backgroundColor: yellow[100],
});
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function SosDetails(props: any) {
  const { t } = useTranslation();
  const id = props?.id;
  const [viewDetail, setOpen] = React.useState("none");

  const handleClickOpen = () => {
    setOpen("block");
  };

  const handleClose = () => {
    setOpen("none");
  };

  const [SosDetail, setSosDetail] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);
  const [Loaded, setLoaded] = React.useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoaded(false);
      GetSosDetails();
    }
  }, [id]);

  const GetSosDetails = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/SOSByID?guid=${id}`,
            {
              headers: { Authorization: `Bearer ${auth_token.token}` },
            }
          )
          .then((res: any) => {
            setLoading(false);
            setSosDetail(res?.data?.Data);
            setLoaded(true);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const [mapHeight, setMapHeight] = useState(400);
  const [sosDetailsWidth, setSosDetailsWidth] = useState(600);
  const [sosGuardsWidth, setSosGuardsWidth] = useState(600);
  const [sosChatWidth, setSosChatWidth] = useState(600);

  return (
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: "#fff",
        height: "100%",
        width: "100%",
        borderRadius: 10,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {Loaded ? (
        <>
          <Grid
            item
            xs={12}
            sx={{ display: { xs: "block", sm: "block", md: "none" } }}
          >
            <Grid
              item
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            >
              <SosMap data={SosDetail} />
            </Grid>
            <Grid
              item
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                height: "40%",
              }}
            >
              <SosMap data={SosDetail} />
            </Grid>
            <SosUserDetails data={SosDetail} viewAudio={true} />
            <SosGuards data={SosDetail} />
            <SosChat data={SosDetail} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            p={1}
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            <SosUserDetails data={SosDetail} viewAudio={true} />
            <SosGuards data={SosDetail} />
            <SosChat data={SosDetail} />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            <SosMap data={SosDetail} />
          </Grid>
        </>
      ) : (
        <Loader />
      )}
    </Grid>
  );
}

export default SosDetails;
